interface LogoProps {
  subtitle?: string;
  stacked?: boolean;
}

export default function Logo({ subtitle, stacked }: LogoProps) {
  return (
    <div className={stacked ? "logo logo__stacked" : "logo"}>
      <img
        className="logo__image"
        src="/images/LOGO_VSTREAMER3.png"
        alt="beneverending logo"
      />
      <div className="logo__subtitle">
        <span>{subtitle}</span>
      </div>
      <div className="logo__header">
        <p>Try The Early Alpha FREE!</p>
      </div>
    </div>
  );
}
