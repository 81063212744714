import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { createUser, userBasics } from "../../../utility/Endpoints";
import Post from "../../../utility/Post";
import { V3UserContext } from "../../../utility/context/V3UserContext";

const style = {
  backgroundColor: "white",
  padding: "1.5rem",
  borderRadius: "8px",
  width: "15rem",
};

interface AddUserProps {
  isOpen: boolean;
  handleClose(): any;
  infoMessage: (message: string) => void;
  errorMessage: (message: string) => void;
}

/**
 * Add a User to the database
 * @param props
 * @returns
 */
export default function AddUser(props: AddUserProps): JSX.Element {
  let navigator = useNavigate();
  const [userInfo, setUserInfo] = useState<{
    email: string,
    pk: number,
    username: string,
    is_organization_manager: boolean,
    is_user_manager: boolean,
    is_active: boolean,
  }>({
    email: "",
    username: "",
    pk: 0,
    is_organization_manager: false,
    is_user_manager: false,
    is_active: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [usernameError, setUsernameError] = useState("")
  const { user } = useContext(V3UserContext)
  const [emailError, setEmailError] = useState("")

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value })
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.checked })
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    var error = false
    if (userInfo.username === "" || userInfo.username.length === 0) {
      setUsernameError("This field cannot be empty.")
      error = true
    }
    else {
      setUsernameError("")
    }
    if (userInfo.email === "" || userInfo.email.length === 0) {
      setEmailError("This field cannot be empty.")
      error = true
    }
    else {
      setEmailError("")
    }

    if (!error && user) {
      setIsLoading(true)

      const FormData = require("form-data");
      const formData = new FormData();
      formData.append("email", userInfo.email);

      //create user
      //then update user basics
      Post(createUser(user.pk), formData).then(val => {
        if (val.status && val.status < 300) {
          if (val.data && val.data.data && val.data.data.pk) {
            const formDataBasic = new FormData();
            formDataBasic.append("username", userInfo.username);
            formDataBasic.append("email", userInfo.email);
            formDataBasic.append("is_organization_manager", userInfo.is_organization_manager);
            formDataBasic.append("is_active", userInfo.is_active);
            formDataBasic.append("is_user_manager", userInfo.is_user_manager);
            Post(userBasics(val.data.data.pk), formDataBasic).then(val1 => {
              if (val1.status && val1.status < 300) {
                props.infoMessage("User created!")
              }
              else {
                if (val1.status === 401) {
                  navigator("/login")
                }
                else { // handle error
                  var temp = Object.keys(val.data.form.errors)[0]
                  props.errorMessage(val.data.form.errors[temp])
                }
              }
              setIsLoading(false)
              props.handleClose()
            })
          }
        } else {
          if (val.status === 401) {
            navigator("/login")
          }
          else { // handle error
            var temp = Object.keys(val.data.form.errors)[0]
            props.errorMessage(val.data.form.errors[temp])
          }
          setIsLoading(false)
          props.handleClose()
        }
      })
    }

  }

  return (
    <Modal open={props.isOpen} onClose={props.handleClose}>
      <Box style={style}>
        <form onSubmit={handleSubmit} >
          <FormControl fullWidth>
            <FormLabel>Add User</FormLabel>
            <TextField
              name="username"
              label="Username"
              margin="dense"
              required
              value={userInfo.username}
              onChange={handleChange}
              error={usernameError !== ""}
              helperText={usernameError}
              disabled={isLoading}
            />
            <TextField
              name="email"
              label="Email"
              margin="dense"
              required
              value={userInfo.email}
              onChange={handleChange}
              error={emailError !== ""}
              helperText={emailError}
              disabled={isLoading}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_organization_manager"
                    checked={userInfo.is_organization_manager}
                    onChange={handleCheck}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={isLoading}
                  />
                }
                label="Organization Manager"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_user_manager"
                    checked={userInfo.is_user_manager}
                    onChange={handleCheck}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={isLoading}
                  />
                }
                label="User Manager"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_active"
                    checked={userInfo.is_active}
                    onChange={handleCheck}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={isLoading}
                  />
                }
                label="Active"
              />
            </FormGroup>
            <Button
              variant="contained"
              sx={{ my: 1 }}
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              <Typography color="white">Add User</Typography>
            </Button>
            <Button
              onClick={props.handleClose}
              variant="contained"
              color="error"
              sx={{ mx: 2 }}
              disabled={isLoading}
            >
              <Typography color="white">Cancel</Typography>
            </Button>
          </FormControl>
        </form>
      </Box>
    </Modal>
  );
}
