import { Button, Container, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

interface TopbarProps {
  searchbar?: boolean;
  handleSearch?: (search: string) => void;
  title: string;
  buttonTitle?: string;
  secondaryButtonTitle?: string;
  handleButton?(): any;
  handleSecondaryButton?(): any;
  isDelete?: boolean;
}

const defaultProps = {
  searchbar: true,
};

/**
 * Displays a searchbar, page title, and a button (optionally 2 buttons)
 * @implements {TopbarProps}
 * @param props
 * @returns
 */
export default function Topbar({
  isDelete = false,
  ...props
}: TopbarProps): JSX.Element {
  const [search, setSearch] = useState("");

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (props.handleSearch) {
      props.handleSearch(search);
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  return (
    <Stack direction="row" spacing={24}>
      <Container sx={{ textAlign: "start", paddingLeft: "0 !important" }}>
        {props.searchbar && (
          <form onSubmit={handleSubmit}>
            <TextField
              id="user-management-searchbar"
              label={"Search"}
              name={"search"}
              fullWidth
              value={search}
              onChange={handleChange}
            />
          </form>
        )}
      </Container>
      <Container
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {props.title}
      </Container>
      <Container
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingRight: "0 !important",
        }}
      >
        {props.secondaryButtonTitle && (
          <Button
            variant="outlined"
            color={isDelete ? "error" : "primary"}
            onClick={props.handleSecondaryButton}
            sx={{ marginRight: "1rem" }}
          >
            <Typography>{props.secondaryButtonTitle}</Typography>
          </Button>
        )}
        {props.buttonTitle && props.handleButton && (
          <Button variant="contained" onClick={props.handleButton}>
            <Typography color="white">{props.buttonTitle}</Typography>
          </Button>
        )}
      </Container>
    </Stack>
  );
}

Topbar.defaultProps = defaultProps;
