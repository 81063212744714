import { useEffect, useState, useRef, useContext } from "react";
import { useLocation } from "react-router";
import AdminNavigation from "./Admin/AdminNavigation";
import UserNavigation from "./User/UserNavigation";
import { Icon } from "../../components/Icon";
import { Tooltip } from "../../components/Tooltip";
import { CartContext } from "../../utility/context/CartContext";
import { V3UserContext } from "../../utility/context/V3UserContext";

/**
 * Contains all Navigation related components
 * @returns
 */
export default function Navigation(): JSX.Element {
  //checks if menu is open or closed
  const [menuOpen, setMenu] = useState<boolean>(false);
  const toggleMenu = () => setMenu((prev) => !prev);
  const closeMenu = () => setMenu(false);
  let location = useLocation();
  const elRef = useRef<HTMLButtonElement | null>(null);
  const { cart } = useContext(CartContext);
  const { pathname } = useLocation();
  const { user } = useContext(V3UserContext);

  useEffect(() => {
    return () => {
      closeMenu();
    };
  }, [location]);

  return (
    <div className="navigation">
      {pathname !== "/display" && (
        <>
          {/* <Logo /> */}
          <header className="topbar">
            <div className="topbar__spacing">
              <a href={"/"} className="topbar__logo" aria-label="Site Logo" >
                <span className="for-screen-readers-only">VStreamer</span>
                <span className="topbar__logo-dimensions">
                  <img
                    style={{ height: "3rem" }}
                    src="/images/LOGO_VSTREAMER1.png"
                    alt="beneverending logo"
                  />
                </span>
                <span className="topbar__title">VStreamer</span>
              </a>

              <div style={{ userSelect: "none" }}>
                <Tooltip content={"Cart"} id={"cart"} position={"bottom"}>
                  <a aria-label={`Cart with ${cart.length} items`} href={"/cart"}>
                    <Icon name="cart" size="sm" variant="black" items={cart.length > 0 ? cart.length : null} />
                  </a>
                </Tooltip>

                &nbsp;&nbsp;&nbsp;
                <button aria-label="Menu" onClick={toggleMenu} ref={elRef}>
                  <Icon
                    id="menuIcon"
                    name={menuOpen ? "close" : "hamburger"}
                    size="sm"
                    className="menuIcon"
                    variant="black"
                  />
                </button>
                &nbsp;&nbsp;&nbsp;
              </div>
            </div>
          </header>
          {user && (user.is_user_manager || user.is_organization_manager) && (
            <AdminNavigation isMenuOpen={menuOpen} setMenuOpen={setMenu} elRef={elRef} />
          )}

          {user && !user.is_user_manager && !user.is_organization_manager && (
            <UserNavigation isMenuOpen={menuOpen} setMenuOpen={setMenu} elRef={elRef} />
          )}
        </>
      )}
    </div>
  );
}
